import { DataTableOptions } from '@/models/dataTableOptions';
import { ListState } from '@/models/listState';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';

const getMutations = () => ({
  resetState: (moduleState: ListState) => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', moduleState);
  },
  updateItems: (moduleState: ListState, data: Array<object>) => {
    moduleState.items = data || [];
  },

  updateItemsPerPage: (moduleState: ListState, numberOfItems: number) => {
    moduleState.itemsPerPage = numberOfItems;
  },

  updateTotal: (moduleState: ListState, total: number) => {
    moduleState.total = total;
  },

  updateCurrentPage: (moduleState: ListState, currentPage: number) => {
    moduleState.currentPage = currentPage;
  },

  clearSelected: (moduleState: ListState) => {
    moduleState.selected = [];
  },

  updateSelected: (moduleState: ListState, selected: Array<object>) => {
    moduleState.selected = selected;
  },

  updateSortOptions: (moduleState: ListState, dataTableOptions: DataTableOptions) => {
    const { sortBy, sortDesc } = dataTableOptions;

    // so api needs either a or d directly in front of the sortfield
    // eg sort=dfirstname,astatus
    if (sortBy.toString().length > 1) {
      moduleState.sort = sortBy.toString();
      // if sortDesc is false then sort ascending
      if (sortDesc.toString() === 'true') {
        moduleState.sortDirection = 'd';
      } else {
        moduleState.sortDirection = 'a';
      }
    } else {
      moduleState.sort = moduleState.defaultSort;
      moduleState.sortDirection = 'a';
    }
  },

  /**
   * Updates the current sort
   *
   * @param {ListState} moduleState
   * @param {string} sortName
   */
  updateSort: (moduleState: ListState, sortName: string): void => {
    moduleState.sort = sortName;
  },

  /**
   * Update sortDirection - a or d
   *
   * @param {ListState} moduleState
   * @param {string} sortDirection
   */
  updateSortDirection: (moduleState: ListState, sortDirection: string): void => {
    moduleState.sortDirection = sortDirection;
  },

  updateLoading: (moduleState: ListState, isLoading: boolean) => {
    moduleState.isLoading = isLoading;
  },

  updateSearch: (moduleState: ListState, searchStr: string) => {
    moduleState.search = searchStr;
  },

  updateSchemeSlug: (moduleState: ListState, schemeSlug: string) => {
    moduleState.schemeSlug = schemeSlug;
  },

  updateErrors: (moduleState: ListState, errorDetail: ErrorIdentity) => {
    if (moduleState.errors) {
      moduleState.errors.push(errorDetail);
    }
  },

  clearErrors: (moduleState: ListState) => {
    moduleState.errors = [];
  },

  updateTab: (moduleState: ListState, tab: number) => {
    moduleState.tab = tab;
  },
});

const getActions = () => ({
  resetState: async (context: ActionContext<ListState, RootState>) => {
    context.commit('resetState');
  },
  fetchItems: async (context: ActionContext<ListState, RootState>, force = false) => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', context, force);
  },
  createItem: async (context: ActionContext<ListState, RootState>, object: any): Promise<any> => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', context, object);
  },

  updateItem: async (context: ActionContext<ListState, RootState>, object: any) => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', context, object);
  },

  deleteItems: async (context: ActionContext<ListState, RootState>, objects: Array<any>) => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', context, objects);
  },

  updatePassword: async (context: ActionContext<ListState, RootState>, object: any) => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', context, object);
  },
  updateUnsubscribes: async (context: ActionContext<ListState, RootState>, object: any): Promise<string> => {
    // eslint-disable-next-line no-console
    console.log('Not implemented', context, object);
    return '';
  },

  resetToEmpty: (context: ActionContext<ListState, RootState>) => {
    context.commit('updateItems', []);
    context.commit('updateTotal', 0);
    context.commit('updateLoading', false);
  },

  /**
   * Updates the data table options on change of table state.
   *
   * @param {ActionContext} context
   * @param {DataTableOptions} dataTableOptions
   * @return void
   */
  updateOptions: (context: ActionContext<ListState, RootState>, dataTableOptions: DataTableOptions) => {
    context.commit('updateCurrentPage', dataTableOptions.page);
    context.commit('updateSortOptions', dataTableOptions);
    context.dispatch('fetchItems');
  },

  /**
   * Updates the current page to reflect pagination choice by user.
   *
   * @param {ActionContext} context
   * @param {number} page
   * @return void
   */
  updatePage: (context: ActionContext<ListState, RootState>, page: number) => {
    context.commit('updateCurrentPage', page);
    context.dispatch('fetchItems');
  },
});

const getGetters = () => ({
  total: (moduleState: ListState) => moduleState.total,
  itemsPerPage: (moduleState: ListState) => moduleState.itemsPerPage,
  pageCount: (moduleState: ListState) => Math.ceil(moduleState.total / moduleState.itemsPerPage),
  items: (moduleState: ListState) => moduleState.items,
  currentPage: (moduleState: ListState) => moduleState.currentPage,
  isLoading: (moduleState: ListState) => moduleState.isLoading,
  selected: (moduleState: ListState) => moduleState.selected,
  search: (moduleState: ListState) => moduleState.search,
  errors: (moduleState: ListState) => moduleState.errors,
});

export { getMutations, getActions, getGetters };
