/**
 * Will set client stylesheet
 */
const applyClientStylesheet = (themeUrl: string): void => {
  if (!themeUrl) {
    // we are using the default scheme, so we can show the body immediately
    document.getElementsByTagName('body')[0].style.display = 'initial';
    return;
  }

  // we are using a custom theme, which will contain a directive to unhide the body
  const head = document.getElementsByTagName('head')[0];
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = themeUrl;
  head.appendChild(link);
};

export { applyClientStylesheet };
