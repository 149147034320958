import moment from 'moment';
import { Member } from '@/models/member';
import { DataTableOptions } from '@/models/dataTableOptions';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { AuthUser } from '@/models/authUser';
import { ResponseError } from '@/models/responseError';
import { useMemberFiltersStore } from '@/stores/member-filters';

/**
 * List state values for the members related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = (): ListState => ({
  itemsPerPage: 10,
  items: [] as Array<Member>,
  currentPage: 1,
  total: 0,
  defaultSort: 'lastname',
  sort: 'lastname',
  sortDirection: 'a',
  isLoading: true,
  selected: [] as Array<Member>,
  search: '',
  errors: [] as Array<ErrorIdentity>,
  selectedMember: {} as Member,
});

const state = getDefaultState();

let previousFilterString: string | null = null;

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: ListState) => {
    Object.assign(moduleState, getDefaultState());
  },
  /**
   * Updates the stores item property.
   * This is the members.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<Member>>} data
   * @return void
   */
  updateItems: (moduleState: ListState, data: Array<Member>) => {
    moduleState.items = data || [];
  },

  /**
   * Updates the number of items per page
   *
   * @param {ListState} moduleState
   * @param {number} noOfItems
   */
  updateItemsPerPage: (moduleState: ListState, noOfItems: number) => {
    moduleState.itemsPerPage = noOfItems;
  },

  /**
   * Updates the stores total property.
   * This is the total number of members that are available from the API, not the total number returned by the paginated fetch requests.
   * Used to create the paginator correctly.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {number} total
   * @return void
   */
  updateTotal: (moduleState: ListState, total: number) => {
    moduleState.total = total;
  },

  /**
   * Updates the stores currentPage property.
   * This is the data tables currently selected page and allows the correct pages members to be called for.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {number} currentPage
   * @return void
   */
  updateCurrentPage: (moduleState: ListState, currentPage: number) => {
    moduleState.currentPage = currentPage;
  },

  /**
   * Updates the stores sort property.
   * This defines the sort order in the request made for a page of members.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Object} dataTableOptions - Currently only the sortBy and sortDesc properties of DataTableOptions.
   * @return void
   */
  updateSortOptions: (moduleState: ListState, dataTableOptions: DataTableOptions) => {
    const { sortBy, sortDesc } = dataTableOptions;

    // so api needs either a or d directly in front of the sortfield
    // eg sort=dfirstname,astatus
    if (sortBy.toString().length > 1) {
      moduleState.sort = sortBy.toString();
      // if sortDesc is false then sort ascending
      if (sortDesc.toString() === 'true') {
        moduleState.sortDirection = 'd';
      } else {
        moduleState.sortDirection = 'a';
      }
    } else {
      moduleState.sort = moduleState.defaultSort;
      moduleState.sortDirection = 'a';
    }
  },

  /**
   * Updates the current sort
   *
   * @param {ListState} moduleState
   * @param {string} sortName
   */
  updateSort: (moduleState: ListState, sortName: string): void => {
    moduleState.sort = sortName;
  },

  /**
   * Update sortDirection - a or d
   *
   * @param {ListState} moduleState
   * @param {string} sortDirection
   */
  updateSortDirection: (moduleState: ListState, sortDirection: string): void => {
    moduleState.sortDirection = sortDirection;
  },

  /**
   * Updates the stores loading property.
   * This updates the loading animations of the data table.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {boolean} isLoading
   * @return void
   */
  updateLoading: (moduleState: ListState, isLoading: boolean) => {
    moduleState.isLoading = isLoading;
  },

  /**
   * Updates the stores selected property.
   * This is the currently selected members on the members table.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @param {Array<Member>} selectedMembers
   * @return void
   */
  updateSelectedMembers: (moduleState: ListState, selectedMembers: Array<Member>) => {
    moduleState.selected = selectedMembers;
  },

  /**
   * Updates the stores selected property.
   * This simply clears the array of currently selected members on the data table.
   *
   * @param {ListState} moduleState - Automatically passed by Vuex.  Current module properties are exposed on this object.
   * @return void
   */
  clearSelectedMembers: (moduleState: ListState) => {
    moduleState.selected = [];
  },

  /**
   * Updates the state of the current search
   *
   * @param {ListState} moduleState
   * @param {string} searchStr
   */
  updateSearch: (moduleState: ListState, searchStr: string) => {
    moduleState.search = searchStr;
  },

  /**
   * Push an error into the error array
   * @param moduleState
   * @param errorDetail
   */
  updateErrors: (moduleState: ListState, errorDetail: ErrorIdentity) => {
    if (moduleState.errors) {
      moduleState.errors.push(errorDetail);
    }
  },

  /**
   * Clear the errors array
   * @param moduleState
   */
  clearErrors: (moduleState: ListState) => {
    moduleState.errors = [];
  },

  /**
   * Updates the currently selected member
   *
   * @param {ListState} moduleState
   * @param {Member} member
   */
  updateSelectedMember: (moduleState: ListState, member: Member) => {
    if (member && member.owners) {
      const parent = member.owners.find((o: AuthUser) => o.status === 'active' && o.email.length);
      if (parent) {
        member.guardian_firstname = parent.firstname;
        member.guardian_lastname = parent.lastname;
        member.guardian_email = parent.email;
      }
    }
    moduleState.selectedMember = member;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 * Actions receive an ActionContext which magically knows the local state and
 * something called root state which is the state in store/index.ts
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<ListState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  /**
   * fetches the members from the API and updates the stores items property with the returned data.
   *
   * @param context
   * @return void
   */
  fetchMembers: async (context: ActionContext<ListState, RootState>) => {
    const apiCurrentPage: number = context.state.currentPage - 1;
    const filter = useMemberFiltersStore().filterString;

    let sort = context.state.sort;
    let sortDirection = context.state.sortDirection;
    if (context.state.sort === 'age') {
      sort = 'dob';
      sortDirection = sortDirection === 'a' ? 'd' : 'a';
    }
    let withProgress = '1';
    if (context.rootGetters.appId === 'scheme' || context.rootGetters['auth/isOrganisationPortal']) {
      withProgress = '0';
    }
    const withTags = context.rootGetters['auth/isOrganisationPortal'] ? '1' : '0';
    const withOrg =
      context.rootGetters['auth/isOrganisationPortal'] && !context.rootGetters['auth/loggedInClubIsOrganisation']
        ? '1'
        : '0';

    const queryParams: Record<string, string> = {
      page: `${apiCurrentPage}`,
      with_groups: '1',
      with_progress: withProgress,
      with_tags: withTags,
      with_owners: '1',
      with_organisations: withOrg,
      per_page: `${context.state.itemsPerPage}`,
      sort: `${sortDirection}${sort}`,
    };
    if (context.state.search && context.state.search.length > 0) {
      queryParams.search = context.state.search;
    }
    // If we have changed the filter string reset the user to the first page
    if ((queryParams.search ?? '') !== previousFilterString) {
      queryParams.page = '0';
      context.state.currentPage = 1;
    }
    previousFilterString = queryParams.search ?? '';
    const url = `/members?${new URLSearchParams(queryParams)}&${filter}`;
    try {
      context.commit('updateLoading', true);
      const authResponse: AuthResponse = await makeRequest('GET', url);
      const totalRecords = authResponse.response.headers.get('X-Total-Count');
      if (totalRecords) {
        context.commit('updateItems', authResponse.body as Array<Member>);
        context.commit('updateTotal', totalRecords);
        context.commit('updateLoading', false);
      } else {
        context.dispatch('resetToEmpty');
      }
    } catch (error) {
      context.commit('updateErrors', error);
      context.dispatch('resetToEmpty');
      console.error(error);
    }
  },

  resetToEmpty: (context: ActionContext<ListState, RootState>) => {
    context.commit('updateItems', []);
    context.commit('updateTotal', 0);
    context.commit('updateLoading', false);
  },

  /**
   * fetches a member based on uuid
   * and updates the store selectedMember
   *
   * @param {ActionContext} context
   * @param {string} memberUuid
   * @return void
   */
  fetchMember: async (context: ActionContext<ListState, RootState>, memberUuid: string) => {
    const withProgress = context.rootGetters['auth/isOrganisationPortal'] ? '0' : '1';
    const withTags = context.rootGetters['auth/isOrganisationPortal'] ? '1' : '0';
    const queryParams: Record<string, string> = {
      with_groups: '1',
      with_progress: withProgress,
      with_tags: withTags,
      with_owners: '1',
    };
    const url = `/members/${memberUuid}?${new URLSearchParams(queryParams)}`;

    const authResponse = await makeRequest('GET', url);
    const member = authResponse.body as Member;
    context.commit('updateSelectedMember', member);
    return member;
  },

  /**
   * Updates the data table options.
   *
   * @param {ActionContext} context
   * @param {DataTableOptions} dataTableOptions
   * @return void
   */
  updateOptions: (context: ActionContext<ListState, RootState>, dataTableOptions: DataTableOptions) => {
    context.commit('updateCurrentPage', dataTableOptions.page);
    context.commit('updateSortOptions', dataTableOptions);
    context.dispatch('fetchMembers');
  },

  /**
   * saves the member either add or edit depending on whether uuid
   * is set or not
   *
   * @param {ActionContext} context
   * @param {Member} member
   * @return void
   */
  saveMember: async (
    context: ActionContext<ListState, RootState>,
    { member, groupId }: { member: Member; groupId: number | null }
  ) => {
    let url = '/members';
    let memberUuid = '';
    let method: 'POST' | 'PATCH' = 'POST';

    const edit = Object.prototype.hasOwnProperty.call(member, 'uuid');

    if (edit) {
      memberUuid = member.uuid;
      url = url + '/' + memberUuid;
      method = 'PATCH';
    }

    const params = {
      firstname: member.firstname.trim(),
      lastname: member.lastname.trim(),
      status: member.status,
      dob: member.dob,
      guardian_firstname: member.guardian_firstname?.trim(),
      guardian_lastname: member.guardian_lastname?.trim(),
      guardian_email: member.guardian_email,
      medical_notes: member.medical_notes,
    } as any;

    if (!edit && groupId) {
      params.group_id = groupId;
    }

    try {
      const resp: AuthResponse = await makeRequest(method, url, { body: JSON.stringify(params) });
      const memberUrlReturned = resp.response.headers.get('Location');
      // get the UUID from the Location header and return it, should be the last value
      if (memberUrlReturned) {
        memberUuid = memberUrlReturned.split('/').pop() as string;
      }
      // fetchMember to update the selectedMember - runs async
      // this will return a member from the api with a fuller set of properties than we gave it
      await context.dispatch('fetchMember', memberUuid);
      return 'ok';
    } catch (error) {
      if (edit && memberUuid) {
        // restore the original member
        await context.dispatch('fetchMember', memberUuid);
      }
      return error as ResponseError;
    }
  },

  /**
   * Updates member progress
   *
   * @param {ActionContext} context
   * @param {DataTableOptions} dataTableOptions
   * @return void
   */
  saveMemberProgress: async (
    context: ActionContext<ListState, RootState>,
    { uuid, progress, origProgress }: { uuid: string; progress: string[]; origProgress: string[] }
  ) => {
    const levelsAdded = progress.filter(x => !origProgress.includes(x));
    const levelsDeleted = origProgress.filter(x => !progress.includes(x));

    for (let index = 0; index < levelsAdded.length; index++) {
      const level = levelsAdded[index];
      const body = JSON.stringify({ node_id: level, assessment: 1, ts: moment().format('X') });
      await makeRequest('POST', `/members/${uuid}/assessments/default`, { body });
    }

    for (let delIndex = 0; delIndex < levelsDeleted.length; delIndex++) {
      const level = levelsDeleted[delIndex];
      const body = JSON.stringify({ node_id: level, assessment: -1, ts: moment().format('X') });
      await makeRequest('POST', `/members/${uuid}/assessments/default`, { body });
    }

    return true;
  },

  /**
   * Updates the currently selected page number for paging in the members data table.
   *
   * @param context
   * @param {number} page
   * @return void
   */
  updateMemberPage: (context: ActionContext<ListState, RootState>, page: number) => {
    context.commit('updateCurrentPage', page);
    context.dispatch('fetchMembers');
  },

  /**
   * Asks the API to resend the parent activation email
   *
   * @param context
   * @param {Member} member
   * @return void
   */
  sendActivationEmail: async (context: ActionContext<ListState, RootState>, member: Member) => {
    try {
      await makeRequest('POST', `/members/${member.uuid}/resend-register-email`);
      return true;
    } catch {
      return false;
    }
  },

  bulkAddMemmbers: async (context: ActionContext<ListState, RootState>, members: Array<Member>) => {
    return await makeRequest('POST', '/members/bulk-add', { body: JSON.stringify({ members }) });
  },

  deleteMember: async (context: ActionContext<ListState, RootState>, memberUuid: string) => {
    try {
      await makeRequest('DELETE', `/members/${memberUuid}`);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  syncBgMember: async (context: ActionContext<ListState, RootState>, member: Member) => {
    try {
      await makeRequest('POST', `/members/${member.uuid}/sync-bg-member`);
      return true;
    } catch {
      return false;
    }
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  itemsPerPage: (moduleState: ListState) => moduleState.itemsPerPage,
  members: (moduleState: ListState) => moduleState.items,
  currentPage: (moduleState: ListState) => moduleState.currentPage,
  total: (moduleState: ListState) => {
    return parseInt(String(moduleState.total), 10);
  },
  isLoading: (moduleState: ListState) => moduleState.isLoading,
  selectedMembers: (moduleState: ListState) => moduleState.selected,
  search: (moduleState: ListState) => moduleState.search,
  errors: (moduleState: ListState) => moduleState.errors,
  selectedMember: (moduleState: ListState) => moduleState.selectedMember,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
