
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Viewer } from '@toast-ui/vue-editor';
import { SchemeScope } from '@/components/mixins/schemescope';
import { mixins } from 'vue-class-component';
import _isEmpty from 'lodash/isEmpty';

@Component({
  components: {
    Viewer,
  },
})
export default class MarkdownViewer extends mixins(SchemeScope) {
  @Prop() private value!: string;
  @Prop() private type!: string;

  get markdown() {
    if (this.type === 'link') {
      // turn links into markdown
      return this.value
        .split(',')
        .map(link => `- ${link}`)
        .join('\n');
    }
    return this.value;
  }

  @Watch('value')
  onValueChanged(value: string) {
    if (value !== null && value !== undefined) {
      const viewer = this.$refs.markdownViewer as any;
      viewer.invoke('setMarkdown', this.markdown);
    }
  }
}
