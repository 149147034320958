
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SchemeModel } from '@/models/schemeModel';

@Component({
  name: 'SchemeList',
  components: {},
})
export default class SchemeList extends Vue {
  @Prop() private routeName!: string;
  private search = '';

  async created() {
    if (this.showAllSchemes) {
      // Show all schemes
      await this.$store.dispatch('schemes/getSchemes');
    }

    if (this.schemes.length === 1) {
      const schemeSlug = this.schemes[0].slug;
      this.$router.push({ name: this.routeName, params: { schemeSlug } });
    }
  }

  get showAllSchemes() {
    return (
      this.groupSchemeSlugs.length === 0 ||
      (this.$store.getters['auth/authProvider'] === 'coursepro' &&
        this.$store.getters['auth/isFeatureEnabled']('CAN_ADD_SESSION_PLAN_GROUP') &&
        this.routeName === 'lesson-plans')
    );
  }

  get groupSchemeSlugs(): Array<string> {
    return this.$store.getters['groups/schemeSlugs'];
  }

  get activeSchemes(): Array<SchemeModel> {
    return this.$store.getters['schemes/schemes'];
  }

  get schemeList(): Array<SchemeModel> {
    return this.$store.getters['schemes/schemeList'];
  }

  get schemes(): Array<SchemeModel> {
    const schemes = this.showAllSchemes ? this.schemeList : this.activeSchemes;
    if (this.search !== '') {
      return schemes.filter(s => s.name.toLowerCase().includes(this.search.toLowerCase()));
    }
    return schemes;
  }
}
