export enum AppIds {
  CLUB = 'club',
  COACH = 'coach',
  SCHEME = 'scheme',
  PARENT = 'parent',
}

export enum ClubMemberTypes {
  LOCAL_ONLY = 'local_only',
  REMOTE_ONLY = 'remote_only',
  LOCAL_AND_REMOTE = 'local_and_remote',
}

export enum AlertI18nKeys {
  ADDED_GROUP = 'alerts.added_group',
  ADDED_MEMBERS = 'alerts.added_members',
  NAME_SEARCH_ERROR = 'alerts.name_search_error',
  NETWORK_ERROR = 'alerts.network_error',
  NO_GROUPS = 'alerts.no_selected_groups',
  NO_MEMBERS = 'alerts.no_selected_members',
  SERVER_ERROR = 'alerts.server_error',
  SPECIAL_SEARCH_ERROR = 'alerts.special_search_error',
  SUCCESSFUL_DELETED_GROUPS = 'alerts.successfully_deleted_groups',
  SUCCESSFUL_LEAVE_MEMBER_FROM_GROUP = 'alerts.successful_leave_group_member',
  UNKNOWN_ERROR = 'alerts.unknown_error',
  UNKNOWN_GROUP = 'alerts.unknown_group',
  UNKNOWN_MEMBER = 'alerts.unknown_member',
  UNKNOWN_MEMBER_OR_GROUP = 'alerts.unknown_member_or_group',
  UPDATE_GROUP_NAME = 'alerts.update_group_name',
  UPDATE_GROUP_NAME_ERROR = 'alerts.update_group_name_error',
}

export enum SearchType {
  NAME_SEARCH = 'alpha_hyphen_space',
  SPECIAL_SEARCH = 'alpha_numeric_hyphen_space_special',
}

let apiUrl = process.env.VUE_APP_API_URL || '';
if (process.env.VUE_APP_TENANT_API_DOMAIN) {
  let parts: string[] = [];
  if (self) {
    parts = self.location.hostname.split('.');
  } else {
    parts = window.location.hostname.split('.');
  }
  if (parts) {
    apiUrl = `https://${parts[0]}.${process.env.VUE_APP_TENANT_API_DOMAIN}`;
  }
}

const getApiUrl = (): string => {
  return apiUrl;
};

const setApiUrl = (url: string): void => {
  apiUrl = url;
};

export { getApiUrl, setApiUrl };
