import { defineStore } from 'pinia';

const defaultFilters = {
  ageMin: 0,
  ageMax: 99,
  levels: [] as string[],
  status: [] as string[],
  in_group: '' as string,
  in_session: '' as string,
};

type Filters = typeof defaultFilters;

function replaceFilter(from: Filters, to: Filters) {
  from.ageMax = to.ageMax;
  from.ageMin = to.ageMin;
  from.levels = [...to.levels];
  from.status = [...to.status];
  from.in_group = to.in_group;
  from.in_session = to.in_session;
}

export const useMemberFiltersStore = defineStore('member-filters', {
  state: () => ({
    filters: {
      saved: { ...defaultFilters },
      current: { ...defaultFilters },
      default: { ...defaultFilters },
    },
    ageMin: 0,
    ageMax: 99,
  }),
  getters: {
    numberOfFiltersApplied(): number {
      return [...this.queryParams.keys()].length;
    },
    queryParams(state): URLSearchParams {
      const filters = state.filters.saved;
      const params = new URLSearchParams();
      if ((filters.ageMin > 0 || filters.ageMax < 80) && filters.ageMin < filters.ageMax) {
        params.append('age[]', `${filters.ageMin}-${filters.ageMax}`);
      }
      filters.levels.forEach(value => {
        params.append('level[]', value);
      });
      filters.status.forEach(value => {
        params.append('status[]', value);
      });
      if (filters.in_group) {
        params.append('in_group', filters.in_group);
      }
      if (filters.in_session) {
        params.append('in_session', filters.in_session);
      }
      return params;
    },
    filterString(): string {
      return decodeURIComponent(this.queryParams.toString());
    },
  },
  actions: {
    async applyFilters(store: any) {
      replaceFilter(this.filters.saved, this.filters.current);
      // Go to page 1 in case we are not there already, results in fetching of members.
      await store.dispatch('members/updateMemberPage', 1);
    },
    undoChanges() {
      replaceFilter(this.filters.current, this.filters.saved);
    },
    resetFilters() {
      replaceFilter(this.filters.current, this.filters.default);
    },
  },
});
