import { ContentType, NodeType, SchemeNodeModel } from '@/models/schemeNodeModel';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  isLevel,
  flatMapLeaves,
  slowGetNode,
  filterTree,
  setIntersection,
  isManualLevel,
  findManualButtonNode,
  isManualButtonId,
  ageFormatted,
} from '@/services/utils';
import _union from 'lodash/union';
import _isEmpty from 'lodash/isEmpty';
import _intersection from 'lodash/intersection';
import { Section } from '@/models/section';
import { GradeRange } from '@/models/labels';
import { SchemeModel } from '@/models/schemeModel';
import { Member, Award } from '@/models/member';
import { Group } from '@/models/group';
import i18n from '@/i18n';
import { AssessmentDetail } from '@/models/assessmentDetail';
import moment from 'moment';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

@Component({
  name: 'SchemeScope',
})
class SchemeScope extends Vue {
  @Prop({ default: '' }) schemeSlug!: string;

  get slug() {
    // NOTE: can be overriden if schemeSlug is calculated
    return this.schemeSlug;
  }

  async initializeSchemeStore(force = false, raw = true) {
    const promises = [];
    if (this.slug) {
      if (_isEmpty(this.scheme) || force) {
        promises.push(this.$store.dispatch('schemes/loadScheme', { raw, slug: this.slug }));
        promises.push(this.$store.dispatch('tags/fetchItems', this.slug));
      }

      if (_isEmpty(this.schemeGrades)) {
        promises.push(this.$store.dispatch('schemes/getGrades', this.slug));
      }

      if (_isEmpty(this.schemeSections)) {
        promises.push(this.$store.dispatch('schemes/getSections', this.slug));
      }
    }

    if (_isEmpty(this.$store.getters['schemes/schemeList'])) {
      promises.push(this.$store.dispatch('schemes/getSchemes'));
    }

    if (_isEmpty(this.$store.getters['labels/labels'])) {
      this.$store.dispatch('labels/fetchLabels');
    }

    if (_isEmpty(this.$store.getters['schemes/videos'])) {
      this.$store.dispatch('schemes/getVideos');
    }

    if (_isEmpty(this.$store.getters['schemes/file'])) {
      this.$store.dispatch('schemes/getFiles');
    }

    await Promise.all(promises);
  }

  get schemes() {
    return this.$store.getters['schemes/schemes'];
  }

  get scheme(): SchemeNodeModel {
    return this.$store.getters['schemes/getScheme'](this.slug);
  }

  get schemeList(): Array<SchemeModel> {
    return this.$store.getters['schemes/schemeList'];
  }

  get schemeFromList(): SchemeModel | null {
    return this.schemeList.find((s: SchemeModel) => s.slug === this.slug) || null;
  }

  getSchemeLevelsInOrder(schemeLookup: Map<string, SchemeNodeModel>) {
    return new Map([...schemeLookup].filter(([, v]) => v.t === NodeType.Level).map(([k], i) => [k, i]));
  }

  getLevelsInOrder(nodeIds: Array<string>) {
    const lookup = this.$store.getters['schemes/getSchemeLookup'](this.slug) as Map<string, SchemeNodeModel>;
    if (!lookup) {
      return nodeIds;
    }
    const schemeLevelsInOrder = this.getSchemeLevelsInOrder(lookup);
    return nodeIds.sort(
      (a: string, b: string) => (schemeLevelsInOrder.get(a) || 0) - (schemeLevelsInOrder.get(b) || 0)
    );
  }

  getNode(schemeNodeId: string): SchemeNodeModel {
    return this.$store.getters['schemes/getNode'](this.slug, schemeNodeId);
  }

  getNodeName(schemeNodeId: string): string {
    return this.$store.getters['schemes/getNodeName'](this.slug, schemeNodeId);
  }

  getNodeTitle(schemeNodeId: string): string {
    const node = this.$store.getters['schemes/getNode'](this.slug, schemeNodeId);
    return node ? node.title : 'Unknown';
  }

  getNodeType(schemeNodeId: string): NodeType {
    return this.$store.getters['schemes/getNodeType'](this.slug, schemeNodeId);
  }

  getNodeTypeName(node: SchemeNodeModel): string {
    if (node.t === NodeType.Level) {
      return this.$t('global.level');
    }
    if (node.t === NodeType.SkillGroup) {
      return this.$t('global.skill_group');
    }
    return this.$t('global.skill');
  }

  getParentNodeIds(schemeNodeId: string): Array<string> {
    return this.$store.getters['schemes/getParentNodeIds'](this.slug, schemeNodeId);
  }

  getDepth(schemeNodeId: string): number {
    // Get node depth in the tree. Root is 0, root's children are 1, etc.
    if (schemeNodeId === '/') {
      return 0;
    }
    if (schemeNodeId === '') {
      return 1;
    }
    const parentIds = this.getParentNodeIds(schemeNodeId);
    return parentIds.length + 1; // getParentNodeIds doesn't include '/'
  }

  getNearestLevel(schemeNodeId: string): string {
    const levels = this.$store.getters['schemes/getParentLevelIds'](this.slug, schemeNodeId);
    return levels.length ? levels[0] : '/';
  }

  getToplevelName(schemeNodeId: string): string {
    const levels = this.getParentNodeIds(schemeNodeId);
    // 0 is self, len-1 is top level (/ is excluded)
    const topLevel = this.getNode(levels.length > 0 ? levels[levels.length - 1] : schemeNodeId);
    return topLevel.title;
  }

  get rawScheme() {
    // used by scheme portal when editing a loaded scheme.
    return this.$store.getters['schemes/schemeRootAll'];
  }

  get schemeAssessibleLevels() {
    return this.$store.getters['schemes/schemeAssessableLevelsOnly'](this.slug);
  }

  get schemeAssessibleNodes() {
    return this.$store.getters['schemes/schemeAssessableNodesOnly'](this.slug);
  }

  schemeAllCompleteLevels(memberCompletedNodeIds: string[], onlyLeaves: boolean) {
    const schemeTree = this.schemeAssessibleNodes;

    const completedTree = filterTree(schemeTree, n => memberCompletedNodeIds.includes(n.id) && isLevel(n));
    if (!completedTree) {
      return [];
    }

    if (!onlyLeaves) {
      return completedTree.children;
    }

    if (completedTree.id === '/' && completedTree.children.length === 0) {
      return []; // nothing completed
    }

    // now take only the leaves of this level tree, ie. if we have /discover and /discover/discover-1, remove /discover
    // we then getNode to make sure we get the full level with all ASSESSIBLE children attached.
    // NOTE: We use slowGetNode because we want to get the version of the node in the filtered tree, without any
    // unassessible children.
    return flatMapLeaves(completedTree, n => slowGetNode(schemeTree, n.id));
  }

  /**
   * Get all levels that can be assessed for the current member or group, including additional.
   *
   * @param memberCurrentLevelIds The current level IDs for the member/members.
   * @param onlyLeaves If true, return a flat array of levels, if false return a partial tree
   * @returns An array of node trees that can be assessed.
   */
  schemeAllAssessableLevels(memberCurrentLevelIds: string[], onlyLeaves: boolean) {
    const additionalAssessmentNodeIds = this.schemeAdditionalAssessmentNodeIds
      .map((nodeId: string) => {
        const node = this.getNode(nodeId);
        if (!('children' in node) || !node.children.length) {
          // skill
          return [node.parentId]; // make sure that the parent of the skill is included
        }
        if (isLevel(node)) {
          // level
          // make sure we include any sub-levels, too, if there are any
          const subLevels = node.children.filter(node => isLevel(node)).map(node => node.id);
          return subLevels.length ? subLevels : [node.id];
        }
        return node.id; // skill group
      })
      .flat()
      // flatten additional assessment node IDs, if you add /early/work-together and /early/work-together/quad, then
      // we flatten this to just /early/work-together
      .filter(
        (nodeId: string, i: number, nodeIds: Array<string>) =>
          !this.getParentNodeIds(nodeId).some((parentNodeId: string) => nodeIds.includes(parentNodeId))
      );

    // You can assess any in-progress levels, and any levels or skill groups that have been added manually.
    const nodeIds = _union(memberCurrentLevelIds, additionalAssessmentNodeIds);
    const schemeTree = this.schemeAssessibleNodes;

    // Otherwise, we want a flat list of levels and skill groups, with all children nodes under each level.
    // We flatten the nodes, so that if you have added a child of a node already in the list, it just shows the parent.
    // We only flatten the additional nodes, since we do not want to flatten /discover/discover-1 into /discover always
    // We only flatten INTO the leaves of the tree, since we do not want to flatten an 'additional' discover-2 into the
    // in-progress discover level with a single child of discover-1, since that wold remove it entirely.
    const inProgressLeaves = flatMapLeaves(
      filterTree(schemeTree, n => memberCurrentLevelIds.includes(n.id)),
      n => n.id
    );

    const flattenedNodeIds = nodeIds.filter(
      nodeId =>
        memberCurrentLevelIds.includes(nodeId) ||
        !this.getParentNodeIds(nodeId).some((parentNodeId: string) => inProgressLeaves.includes(parentNodeId))
    );

    // filter the tree to include only levels that are in progress (excludes children)
    const inProgressTree = filterTree(schemeTree, n => flattenedNodeIds.includes(n.id));
    if (!inProgressTree) {
      return [];
    }

    if (!onlyLeaves) {
      return inProgressTree.children;
    }

    // nothing in progress
    if (inProgressTree.id === '/' && inProgressTree.children.length === 0) {
      return [];
    }

    // now take only the leaves of this level tree, ie. if we have /discover and /discover/discover-1, remove /discover
    // we then getNode to make sure we get the full level with all ASSESSIBLE children attached.
    // NOTE: We use slowGetNode because we want to get the version of the node in the filtered tree, without any
    // unassessible children.
    return flatMapLeaves(inProgressTree, n => slowGetNode(schemeTree, n.id));
  }

  get schemeAdditionalAssessmentNodeIds() {
    if (!this.$store.getters['auth/isFeatureEnabled']('ENABLE_ADDITIONAL_ASSESSMENTS')) {
      return [];
    }
    return this.$store.getters['schemes/additionalAssessmentNodeIds'](this.slug);
  }

  getNodePath(schemeNodeId: string): string {
    return `/scheme/${this.slug}${schemeNodeId}`;
  }

  get schemeSections(): Array<Section> {
    return this.$store.getters['schemes/sections'](this.slug) || [];
  }

  get schemeGrades(): GradeRange {
    return this.$store.getters['schemes/grades'](this.slug) || [];
  }

  get schemeGradeList(): { value: number[]; text: string }[] {
    return Object.keys(this.schemeGrades).map((key: string) => {
      const id = parseInt(key, 10);
      return {
        total: id,
        value: [0, id, id],
        text: `${this.$t('assessments.standard_grades')} - ${this.schemeGrades[id].length}`,
      };
    });
  }

  get schemeSectionsWithoutTitle(): Array<Section> {
    return this.schemeSections.filter((s: Section) => s.type !== ContentType.Title);
  }

  get schemeSectionsOrder(): Map<number, number> {
    const map = new Map();
    for (const s of this.schemeSections) {
      map.set(s.id, s.ordering);
    }
    return map;
  }

  /**
   * Returns a formatted string of the members current number of sub skills completed alongside the total available to be assessed on.
   *
   * @param {SchemeNodeModel} node
   * @return {string}
   */
  getMemberAssessmentsComplete(node: SchemeNodeModel, member: Member, forcePassed = false) {
    // NOTE: progress is the number of children out of the total children, not the number of completed nodes
    // out of the total required nodes (ie. the completion array)
    const childrenNodeIds = new Set(node.children.filter(c => !isManualButtonId(c.id)).map(c => c.id));
    const toComplete = node.completion ? node.completion[1] : 0;
    const completed = node.id in member.assessments.complete && member.assessments.complete[node.id] === true;
    const completedNodeIds = new Set(Object.keys(member.assessments.complete));
    const intersection = setIntersection(completedNodeIds, childrenNodeIds);
    const text = `${intersection.size}/${childrenNodeIds.size}`;
    if (forcePassed) {
      return `${text} (${i18n.t('scheme.forced_pass')})`;
    }
    if (completed) {
      if (member.awards && member.awards[node.id]) {
        // we also show the date the level was passed
        const award: Award = member.awards[node.id];
        const ago = moment.unix(award.completed_ts).fromNow();
        return `${text} (${i18n.t('scheme.level_passed_ago', { ago })})`;
      }
      const passed = isLevel(node) ? 'scheme.level_passed' : 'scheme.node_passed';
      return `${text} (${i18n.t(passed)})`;
    }
    if (isManualLevel(node)) {
      // get the manual button node from the full node including all unassessible children
      const manualButtonNode = findManualButtonNode(this.getNode(node.id));
      if (manualButtonNode && manualButtonNode.dependencies) {
        const required = manualButtonNode.dependencies[1];
        return `${text} (${i18n.t('scheme.to_pass_manual', { toComplete: required })})`;
      }
    }
    return `${text} (${i18n.t('scheme.to_pass', { toComplete })})`;
  }
}

@Component({
  name: 'GroupSchemeScope',
})
class GroupSchemeScope extends SchemeScope {
  @Prop() groupId!: number;

  get group(): Group {
    return this.$store.getters['groups/groupById'](this.groupId);
  }

  get slug() {
    return this.group.scheme_slug;
  }

  get showPassAllButton(): boolean {
    return this.$store.getters['auth/isFeatureEnabled']('SHOW_PASS_ALL_BUTTON');
  }

  passLevel(member: Member, node: SchemeNodeModel | null) {
    if (!node) {
      return false;
    }
    // actually passes a manual level by recording assessment against the manual button
    const manualButtonNode = findManualButtonNode(this.getNode(node.id));
    if (manualButtonNode) {
      return this.passSkill(member, manualButtonNode);
    }
    return false;
  }

  unpassLevel(member: Member, node: SchemeNodeModel | null) {
    if (!node) {
      return false;
    }
    const manualButtonNode = findManualButtonNode(this.getNode(node.id));
    if (manualButtonNode) {
      return this.unpassSkill(member, manualButtonNode);
    }
    return false;
  }

  async passSkill(member: Member, node: SchemeNodeModel | null) {
    if (node?.assessment) {
      return this.assessNode(member.uuid, node, node.assessment[2]);
    }
    return false;
  }

  async unpassSkill(member: Member, node: SchemeNodeModel) {
    return this.assessNode(member.uuid, node, -1);
  }

  async assessNode(memberUuid: string, node: SchemeNodeModel, grade: number) {
    if (!node.assessment) {
      return false;
    }

    const assessmentDetail: AssessmentDetail = {
      memberUuid: memberUuid,
      groupId: this.groupId,
      groupNgoId: this.group.ngo_id || null,
      schemeSlug: this.slug,
      schemeNodeId: node.id,
      assessmentGrade: grade,
      assessmentPassGrade: node.assessment[2],
      assessmentDate: moment().format('YYYY-MM-DD'),
      timestamp: moment().format('X'),
    };

    try {
      // To avoid a race condition where multiple nodes are being assessed at the same time, we force all the assessment
      // to syncronise here, so each ones update the store one after the other. This avoids the case where one call has
      // loaded the current progress from the store, then another call saves its updated progress, then the first call
      // overwrites that with its new progress, removing the previous assessment.
      await mutex.runExclusive(async () => {
        await this.$store.dispatch('groupMembers/updateAssessmentInDbStore', assessmentDetail);
      });
    } catch (error) {
      console.error(error);
      return false;
    }

    // the assessment is sent to the API in the background -- or on reconnect if we are offline
    this.$store.dispatch('groupMembers/updateAssessmentInApi', assessmentDetail);
    return true;
  }

  get groupLevels(): null | string[] {
    if (this.group && this.group.levels && this.group.levels.length > 0) {
      return this.group.levels;
    }
    return null;
  }

  get firstGroupLevel(): null | string {
    if (!this.groupLevels) {
      return null;
    }
    return this.getLevelsInOrder(this.groupLevels)[0];
  }

  getMemberActiveLevels(member: Member): string[] {
    if (this.groupLevels !== null) {
      if (this.group.assessment_level_limit) {
        const current = member.current_remote_node_id ?? this.firstGroupLevel;
        return current && this.groupLevels.includes(current) ? [current] : [];
      }
      return this.groupLevels;
    }

    return Object.keys(member.progress || []) as Array<string>;
  }

  getMemberCurrentLevel(member: Member): string {
    if (this.groupLevels) {
      if (member.current_remote_node_id && this.groupLevels.includes(member.current_remote_node_id)) {
        return member.current_remote_node_id;
      }
      if (this.firstGroupLevel) {
        return this.firstGroupLevel;
      }
    }
    return Object.keys(member.progress || [])[0];
  }

  get showCurrentLevel(): boolean {
    return this.$store.getters['auth/isFeatureEnabled']('SHOW_CURRENT_LEVEL');
  }

  memberLegend(member: Member, alwaysShowDob = false): string {
    const cache = this.$store.getters['groupMembers/getMemberLegendCache'](member.uuid, alwaysShowDob);
    if (cache) {
      return cache;
    }
    if (this.showCurrentLevel && !alwaysShowDob) {
      const currentLevel = this.getMemberCurrentLevel(member);
      const node = this.getNode(currentLevel);
      const nodeTitle = `(${node.title})`;
      this.$store.dispatch('groupMembers/setMemberLegendCache', {
        memberUuid: member.uuid,
        legend: nodeTitle,
        dob: null,
      });
      return nodeTitle;
    }
    const dob = ageFormatted(member.dob);
    this.$store.dispatch('groupMembers/setMemberLegendCache', { memberUuid: member.uuid, dob, legend: null });
    return dob;
  }

  async loadPlannedSkills() {
    if (this.group.session_plan_uuid) {
      const sessionPlan = this.$store.getters['lessonPlans/selectedPlan'];
      if (!sessionPlan || !sessionPlan.sections || sessionPlan.uuid !== this.group.session_plan_uuid) {
        await this.$store.dispatch('planning/fetchItems');
        await this.$store.dispatch('lessonPlans/fetchLessonPlanFromDb', this.group.session_plan_uuid);
      }
    } else {
      this.$store.commit('lessonPlans/updateSelected', {});
    }
  }

  haveLessonPlan() {
    const plannedSkills = this.$store.getters['lessonPlans/sessionSkillIds'];
    return plannedSkills && plannedSkills.length;
  }

  isPlannedSkill(node: SchemeNodeModel) {
    const plannedSkills = this.$store.getters['lessonPlans/sessionSkillIds'];
    return plannedSkills && plannedSkills.includes(node.id as string);
  }

  get showAdditionalAssessments() {
    const isSlot = this.group.start_at && this.group.start_at.length !== 0;
    return (
      isSlot ||
      (this.$store.getters['auth/isFeatureEnabled']('ENABLE_ADDITIONAL_ASSESSMENTS') &&
        !this.group.assessment_level_limit)
    );
  }
}

@Component({
  name: 'DefaultSchemeScope',
})
class DefaultSchemeScope extends SchemeScope {
  get slug() {
    // Just always use the default slug
    return this.$store.getters['schemes/defaultSchemeSlug'];
  }
}

@Component({
  name: 'OptionalSchemeScope',
})
class OptionalSchemeScope extends SchemeScope {
  get slug() {
    if (this.schemeSlug) {
      return this.schemeSlug;
    }
    // default slug if only one scheme available
    if (this.schemes.length === 1) {
      return this.schemes[0].slug;
    }
    // no slug, show a list of schemes
    return '';
  }
}

export { SchemeScope, GroupSchemeScope, OptionalSchemeScope, DefaultSchemeScope };
