import { Club } from '@/models/club';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';

/**
 * List state values for the tags related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = () => ({
  clubs: [] as Array<Club>,
});

const state = getDefaultState();

type GlobalState = typeof state;

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: GlobalState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateClubs: (moduleState: GlobalState, data: Array<Club>) => {
    moduleState.clubs = data || [];
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<GlobalState, RootState>): Promise<void> => {
    context.commit('resetState');
  },

  /**
   * Fetches clubs
   *
   * @param {ActionContext} context
   * @return void
   */
  fetchClubs: async (context: ActionContext<GlobalState, RootState>) => {
    try {
      const authResponse: AuthResponse = await makeRequest('GET', '/clubs?with_logos=1&with_languages=1');
      context.commit('updateClubs', authResponse.body as Array<Club>);
    } catch (error) {
      console.error(error);
    }
  },

  deleteClubs: async (context: ActionContext<GlobalState, RootState>, clubs: Array<Club>) => {
    await Promise.all(
      clubs.map(async (club: Club) => {
        const url = `/clubs/${club.id}`;
        return await makeRequest('DELETE', url);
      })
    );
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  clubs: (moduleState: GlobalState) => moduleState.clubs,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
