
import { Component, Prop } from 'vue-property-decorator';
import MarkdownViewer from '@/components/common/MarkdownViewer.vue';
import { SchemeScope } from '@/components/mixins/schemescope';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    MarkdownViewer,
  },
})
export default class RenderSkillContent extends mixins(SchemeScope) {
  @Prop() private type!: string;
  @Prop() private value!: string;

  mounted() {
    // Add a native event listener to the Vue element.
    this.$el.addEventListener('click', this.onHtmlClick);
  }

  onHtmlClick(event: any) {
    // Check to make sure this is from the markdown-viewer and is a valid node link
    const el = event.target.closest('a');
    if (el) {
      try {
        const url = new URL(el.href);
        // Only rewrite non external links
        if (url.pathname.charAt(0) === '/' && url.host === window.location.host) {
          event.stopPropagation();
          event.preventDefault();
          const nodeId = url.pathname.replace(`/scheme/${this.slug}`, '');
          const node = this.getNode(nodeId);
          if (node) {
            this.$emit('link-clicked', nodeId);
          } else {
            this.$alert('error', this.$t('scheme.skill_or_level_not_found'));
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  get isText() {
    const textTypes = ['title', 'text', 'warning', 'link', 'text-no-link'];
    return textTypes.includes(this.type);
  }

  get files() {
    const files = [];
    if (this.type === 'files') {
      for (const file of this.value) {
        const f = Object.assign({ open: false }, file);
        files.push(f);
      }
    }
    return files;
  }

  get tags() {
    return this.type === 'tags' ? this.value : [];
  }

  isImage(fileName: string) {
    const images = ['.gif', '.jpg', '.jpeg', '.png', '.svg'];
    return images.some(substring => fileName && fileName.toLowerCase().endsWith(substring));
  }
}
