import { EventBus } from '@/services/event-bus';
import { Alert } from '@/models/alert';
import { TranslateResult } from 'vue-i18n';

const Alert = {
  install(Vue: any) {
    Vue.prototype.$alert = (type: Alert['type'], text: string | TranslateResult, duration?: number) => {
      const alert: Alert = {
        type,
        text: `${text}`,
        duration: duration || 5000,
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      };
      EventBus.$emit('global-alert', alert);
    };
  },
};

export default Alert;
