import Vue from 'vue';
import Scheme from './Scheme.vue';
import routes from './router/scheme';
import store from './store/scheme';
import vuetify from './plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import GlobalAlert from './plugins/global-alert';
import ConfirmDangerous from './plugins/confirm-dangerous';
import i18n from './i18n';
import VueHead from 'vue-head';
import VueRouter from 'vue-router';
import { authGuard } from '@/services/auth';
import VueDragDrop from 'vue-drag-drop';
import VueGtag from 'vue-gtag';
import pinia from './plugins/pinia';

Vue.config.productionTip = false;
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(ConfirmDangerous, { vuetify });
Vue.use(GlobalAlert);
Vue.use(VueHead);
Vue.use(VueDragDrop);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(authGuard(store));

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SCHEME_GOOGLE_ANALYTICS_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_SCHEME_GOOGLE_ANALYTICS_ID },
    },
    router
  );
}

new Vue({
  router,
  store,
  vuetify,
  pinia,
  i18n,
  render: h => h(Scheme),
}).$mount('#app');
