import i18n from '@/i18n';
import { NavigationGuardNext, Route } from 'vue-router';
import { ResponseError } from '@/models/responseError';
import { AuthResponse } from '@/models/authResponse';
import { TokenResponse } from '@/models/tokenResponse';
import { EventBus } from '@/services/event-bus';
import merge from 'lodash/merge';
import { AuthAccess } from '@/models/authAccess';
import { Store } from 'vuex';
import { RootState } from '@/models/rootState';
import { ApiLanguage } from '@/models/language';
import { getApiUrl } from '@/consts';
import fetchRetry from 'fetch-retry';
import { useLanguage } from '@/composables/use-language';

// Retry failing requests up to 3 times with backoff in the case of network errors or 503 (proxy error)
const retryFetch = fetchRetry(global.fetch, {
  retryOn: [502, 503, 504],
  retryDelay: attempt => Math.random() * 1000 + Math.pow(2, attempt) * 1500, // 1.5s, 3s, 12s (+0-1s random)
});

const parseJwt = (token: string): any => {
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    EventBus.$emit('invalid-token');
    throw new Error('Invalid Token');
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
const parseTokenSubject = (token: string): AuthAccess => {
  const parsedToken = parseJwt(token);
  return JSON.parse(parsedToken.sub) as AuthAccess;
};
const getRefreshTokenExpiry = (refreshToken: string | null): number => {
  // Return refresh token expiry in seconds
  if (refreshToken) {
    const parsedToken = parseJwt(refreshToken);
    const expiry = JSON.parse(parsedToken.exp);
    return expiry * 1000;
  }
  return 0;
};

const retrieveToken = () => localStorage.getItem('token');
const retrieveRefreshToken = () => localStorage.getItem('refresh_token');

const getBaseUrl = (): string => {
  return getApiUrl() + '/' + process.env.VUE_APP_API_VERSION;
};

const retrieveJsonKey = (key: string) => {
  try {
    const str = localStorage.getItem(key);
    return str ? JSON.parse(str) : null;
  } catch {
    return null;
  }
};
const retrieveClubs = () => {
  return retrieveJsonKey('clubs');
};
const retrieveLanguage = () => {
  return retrieveJsonKey('language');
};
const saveJsonToLocalStorage = (name: string, data: Array<string>) => {
  localStorage.setItem(name, JSON.stringify(data));
};
const saveLanguageToLocalStorage = (language: ApiLanguage) => {
  const languageRef = useLanguage();
  languageRef.value = language;
  localStorage.setItem('language', JSON.stringify(language));
};

const sendTokenToServiceWorker = (refreshToken: string) => {
  // Send the new tokens to the service worker to be used when replaying
  if (navigator instanceof Navigator && navigator.serviceWorker !== undefined && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'setToken',
      refresh_token: refreshToken,
    });
  }
};

const saveNewTokenResponse = (tokenResponse: TokenResponse): [AuthAccess, number] => {
  localStorage.setItem('token', tokenResponse.token);
  localStorage.setItem('refresh_token', tokenResponse.refresh_token);
  sendTokenToServiceWorker(tokenResponse.refresh_token);

  if (tokenResponse.clubs) {
    saveJsonToLocalStorage('clubs', tokenResponse.clubs);
  }
  if (tokenResponse.club_users) {
    // Users who can be switched to for each club
    saveJsonToLocalStorage('clubUsers', tokenResponse.club_users);
  }
  const data = parseTokenSubject(tokenResponse.token);
  const refreshTokenExpiry = getRefreshTokenExpiry(tokenResponse.refresh_token);

  localStorage.setItem('loginTs', Date.now().toString());

  // So we can know the originating user if they switch user with a pin code
  localStorage.setItem('origUserUuid', data.user_uuid);
  saveLanguageToLocalStorage(data.language);

  return [data, refreshTokenExpiry];
};

const getClubUsers = () => {
  const clubUsers = retrieveJsonKey('clubUsers');
  if (!clubUsers) {
    return [];
  }
  return clubUsers;
};

const clearLocalStorage = () => {
  const items = ['token', 'refresh_token', 'clubs', 'features', 'clubUsers', 'origUserUuid', 'loginTs'];
  for (const item of items) {
    localStorage.removeItem(item);
  }
};

const appTypetoAuthType = new Map();
appTypetoAuthType.set('coach', 'coach');
appTypetoAuthType.set('scheme', 'ngo');
appTypetoAuthType.set('club', 'admin');
appTypetoAuthType.set('parent', 'parent');

const appTypeToAuthType = (appId: string): string => {
  return appTypetoAuthType.get(appId);
};

const fetchJSON = async (url: string, options: RequestInit = {}) => {
  // add Content-Type header if we are sending a body
  const contentTypeHeader = { headers: { 'Content-Type': 'application/json' } };
  const fetchOptions = Object.prototype.hasOwnProperty.call(options, 'body')
    ? merge({}, options, contentTypeHeader)
    : options;

  // retry GET requests not /status and also POST /auth/refresh
  const method =
    (options.method === 'GET' && !url.endsWith('/status')) || url.endsWith('/auth/refresh') ? retryFetch : fetch;

  const response = await method(url, fetchOptions);
  let body = null;
  try {
    body = await response.clone().json(); // clone in case we need to get text on failure
  } catch (error) {
    body = await response.text();
  }
  return { response, body } as AuthResponse;
};

const authFetch = async (url: string, options: RequestInit = {}) => {
  const r = await fetchJSONWithToken(url, options);
  if (
    r.response.status === 401 &&
    Object.prototype.hasOwnProperty.call(r.body, 'error') &&
    r.body.error === 'TOKEN_EXPIRED'
  ) {
    await refreshToken();
    // try again, if it fails again we just return that error
    return fetchJSONWithToken(url, options);
  }
  return r;
};

const getValidRefreshToken = (): null | string => {
  // if they have a refresh token then it will get them a new access token
  // so is more important to check this than the shortlived access token
  const refreshToken = retrieveRefreshToken();
  return getRefreshTokenExpiry(refreshToken) < Date.now() ? null : refreshToken;
};

const resetPassword = async (email: string, clientId: string): Promise<boolean> => {
  try {
    const url = `${getBaseUrl()}/auth/reset-password`;
    const resp: AuthResponse = await fetchJSON(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, client_id: clientId }),
    });
    return resp.response.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const fetchJSONWithToken = async (url: string, options: RequestInit = {}): Promise<AuthResponse> => {
  const token = retrieveToken();
  if (!token || token === 'undefined') {
    EventBus.$emit('invalid-token');
    throw new Error('Invalid Token');
  }
  const fetchOptions = merge({}, options, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return fetchJSON(url, fetchOptions);
};

const fetchNewToken = async (refreshToken: string) => {
  const url = getBaseUrl() + '/auth/refresh';
  const response: AuthResponse = await fetchJSON(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });
  return response.body as { token: string };
};

// Call to refresh the token.
const refreshToken = async (): Promise<void> => {
  const refreshToken = getValidRefreshToken();
  if (refreshToken) {
    try {
      const responseBody = await fetchNewToken(refreshToken);
      localStorage.setItem('token', responseBody.token);
      EventBus.$emit('update-token', responseBody.token);
      return Promise.resolve();
    } catch (error) {
      console.error('Error refreshing access token: ', error);
    }
  }
  EventBus.$emit('invalid-token');
  return Promise.reject(Error('Invalid refresh token'));
};

const showAdvanced = () => {
  return localStorage.getItem('show_advanced') !== null;
};

const pathDisabled = (to: Route, store: Store<RootState>) => {
  if (to.path === '/users' && store.getters.appId === 'club') {
    // the user page is only accessible in clubportal if you have a fully-local club or in organisation portal
    return !store.getters['auth/isOrganisationPortal'] && store.getters['auth/loggedInClubHasRemoteMembers'];
  }
  if (to.path === '/coaches' && store.getters.appId === 'club') {
    // coaches disabled in organisation portal
    return store.getters['auth/isOrganisationPortal'];
  }
  if (to.path === '/sessions' && store.getters.appId === 'club') {
    // Hide sessions for non coursepro customers
    return store.getters['auth/authProvider'] !== 'coursepro';
  }
  if (to.path === '/tasks' || (to.path === '/members' && store.getters.appId === 'scheme')) {
    // This is set manually if you wish to show advanced functionality for support users.
    return !showAdvanced();
  }
  if (store.getters.appId === 'scheme' && (to.path === '/clubs' || to.path === '/users')) {
    // Hide Clubs and Users for coursepro customers
    return store.getters['auth/authProvider'] === 'coursepro';
  }
  if (to.path === '/switch-user') {
    // Only for coursepro users who have permission
    const clubUsers = getClubUsers();
    return store.getters['auth/authProvider'] !== 'coursepro' || clubUsers.length === 0;
  }
  return false;
};

const isProtectedPath = (path: string) => {
  const unprotectedPaths = ['/login', '/logout', '/redirect', '/set-password', '/reset-password', '/unsubscribe'];
  return unprotectedPaths.includes(path) === false;
};

const authGuard = (store: Store<RootState>) => (to: Route, from: Route, next: NavigationGuardNext) => {
  window.scrollTo(0, 0);
  const refreshToken = getValidRefreshToken();
  if (isProtectedPath(to.path) && refreshToken === null) {
    next({ name: 'login', query: { logout: '1', returnUrl: to.fullPath } });
  } else if (pathDisabled(to, store)) {
    next('/'); // back to home page
  } else if (from.path === '/switch-user' && !to.query.switch && to.path !== '/login') {
    next({ name: 'switch-user' });
  } else {
    next();
  }
};

const getLoginErrorString = (err: ResponseError): string => {
  // detail can be ERROR_FETCH (couldn't connect to bgapi API), ERROR_INVALID_EMAIL, UNKNOWN_ERROR,
  // or one of the errors listed in BGAPI's AuthProvider:checkCredentials.

  // Special case for ERROR_INVALID_EMAIL
  const type =
    typeof err.body.detail === 'object' && 'email' in err.body.detail ? 'ERROR_INVALID_EMAIL' : err.body.detail;

  const key = `login.errors.${type}`;
  if (i18n.te(key)) {
    return i18n.t('login.errors.failed', { detail: i18n.t(key) });
  }
  return i18n.t('login.errors.unknown', { detail: err.body.detail });
};

export {
  authGuard,
  isProtectedPath,
  pathDisabled,
  authFetch,
  fetchJSON,
  resetPassword,
  parseTokenSubject,
  getRefreshTokenExpiry,
  appTypeToAuthType,
  retrieveToken,
  retrieveRefreshToken,
  retrieveClubs,
  retrieveLanguage,
  clearLocalStorage,
  getBaseUrl,
  saveLanguageToLocalStorage,
  retrieveJsonKey,
  saveJsonToLocalStorage,
  getClubUsers,
  saveNewTokenResponse,
  getValidRefreshToken,
  getLoginErrorString,
  fetchNewToken,
  sendTokenToServiceWorker,
  refreshToken,
  showAdvanced,
};
