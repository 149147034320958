
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SchemeNodeModel } from '@/models/schemeNodeModel';
import { sendGAVideoViewEvent } from '@/services/utils';
import { AppIds } from '@/consts';

@Component
export default class RenderSkillVideo extends Vue {
  @Prop() private skill!: SchemeNodeModel;
  private videoUrls: { id: string; url: string }[] = [];
  private analyticsSent = false;

  async created() {
    this.fetchUrls();
  }

  get appId() {
    return this.$store.getters.appId as AppIds;
  }

  @Watch('skill.videos')
  async fetchUrls() {
    // Extracts videos from skill's content and fetches video URLs
    this.videoUrls = await this.$store.dispatch('schemes/getVideoUrls', this.skill);
  }

  onPlay(videoName: string) {
    if (!this.analyticsSent && this.$gtag) {
      sendGAVideoViewEvent(
        this.$gtag,
        videoName,
        this.appId === 'coach' ? 'Coach Portal' : 'Home Portal',
        process.env.VUE_APP_VIDEO_GOOGLE_ANALYTICS_ID
      );
      this.analyticsSent = true;
    }
  }
}
