
import { Component, Prop, Vue } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import { AlertI18nKeys, SearchType } from '@/consts';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { VForm } from '@/types/v-form';

@Component
export default class SearchField extends Vue {
  @Prop() private searchStoreName!: string;
  @Prop() private searchFunction!: (searchParams: unknown) => void | string;
  @Prop() private searchTypeName!: string;
  @Prop() private searchParams: unknown;

  private debouncedDoSearch: unknown;

  /**
   * This was the only way I could get the debounce working on the doSearch
   * from the official vue docs
   */
  created() {
    this.debouncedDoSearch = debounce(() => {
      this.doSearch();
    }, 500); // milliseconds
  }

  /**
   * Computed getter.  Returns a translation of the search text.
   *
   * @return string
   */
  get searchLabel(): string {
    return this.$t('global.search') as string;
  }

  /**
   * Gets translated general error message if put in the wrong input for search
   */
  get searchAlertMessage() {
    if (this.searchTypeName === 'NAME_SEARCH') {
      return this.$t(AlertI18nKeys.NAME_SEARCH_ERROR).toString();
    } else {
      return this.$t(AlertI18nKeys.SPECIAL_SEARCH_ERROR).toString();
    }
  }

  /**
   * Returns the forms ref object allowing access to Vuetify build its form methods.
   *
   * @return VForm
   */
  getSearchForm(): VForm {
    return this.$refs.searchForm as VForm;
  }

  /**
   * Computed getter for the current search string.
   * Returned from the store.
   * Stores current search string
   *
   * @return {string}
   */
  get search() {
    const searchStr = this.$store.getters[this.searchStoreName + '/search'];
    if (searchStr !== undefined) {
      return this.$store.getters[this.searchStoreName + '/search'].toString();
    } else {
      return '';
    }
  }

  /**
   * Gets called on every char you type in the search box
   * but debouncedDoSearch will delay the calling of the actual search
   * until the user has probably finished typing.
   *
   * At the very least it limits the calls to the search function.
   *
   * Sets the search string if the length of the search is greater then 3
   *
   * If the length of the search is 0 then it will reset the results
   */
  set search(value: string) {
    const searchForm = this.getSearchForm();
    const validated = searchForm.validate();

    // when click on clearable value becomes null which breaks stuff
    if (value === undefined || value === null) {
      value = '';
    }
    if (value.length >= 2 || value.length === 0) {
      // clear any previous errors
      this.clearSearchAlerts();
      if (validated) {
        this.$store.commit(this.searchStoreName + '/updateSearch', value);
        if (typeof this.debouncedDoSearch === 'function') {
          this.debouncedDoSearch(value);
        }
      }
    }
  }

  get searchType() {
    if (this.searchTypeName === 'NAME_SEARCH') {
      return SearchType.NAME_SEARCH;
    } else {
      return SearchType.SPECIAL_SEARCH;
    }
  }

  /**
   * Validation rules for the search configured to match API
   * Regex matches backend validation rule currently in relevant Controller
   *
   * If its length 0 or empty then that also passes validation so that it doesn't stay invalid when cleared
   *
   * @return string | boolean
   */
  get searchRules() {
    if (this.searchType === SearchType.NAME_SEARCH) {
      // only allow word chars,hyphens and spaces
      return [(v: string) => !v || v.length === 0 || /^[\w_\s-]+$/u.test(v) || this.searchAlertMessage];
    } else {
      // default to SearchType.SPECIAL_SEARCH restrict special chars allowed
      return [(v: string) => !v || v.length === 0 || /^[\w\s_,.':-@]+$/u.test(v) || this.searchAlertMessage];
    }
  }

  /**
   * Needed to write a separate function so that I could create a debounced version of it in the created event
   * Won't be called on every char only
   */
  async doSearch() {
    if (this.searchFunction instanceof Function) {
      await this.searchFunction(this.searchParams);
    } else {
      await this.$store.dispatch(this.searchFunction, this.searchParams);
    }

    if (this.$store.getters[this.searchStoreName + '/errors'].length > 0) {
      const messages: Array<string> = [];
      this.$store.getters[this.searchStoreName + '/errors'].forEach((errorInfo: ErrorIdentity) => {
        if ({}.hasOwnProperty.call(errorInfo, 'search')) {
          const searchAlertMessage = this.searchAlertMessage;
          if (messages.indexOf(searchAlertMessage) === -1) {
            messages.push(searchAlertMessage);
          }
        }
      });
      if (messages.length > 0) {
        messages.forEach(message => this.$alert('warning', message as string));
        this.clearSearchAlerts();
      }
    } else {
      this.clearSearchAlerts();
    }
  }

  /**
   * Clear errors and searchAlert messages
   */
  private clearSearchAlerts() {
    this.$store.commit(this.searchStoreName + '/clearErrors');
  }
}
