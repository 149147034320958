import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import auth from '@/store/modules/auth';
import clubs from '@/store/modules/clubs';
import labels from '@/store/modules/labels';
import nav from '@/store/modules/nav';
import notifications from '@/store/modules/notifications';
import schemes from '@/store/modules/schemes';
import tags from '@/store/modules/tags';
import users from '@/store/modules/users';
import members from '@/store/modules/members';
import { state, mutations, getters, IState } from './root';
import { useMemberFiltersStore } from '@/stores/member-filters';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions: {
    resetState: async (context: ActionContext<IState, IState>) => {
      useMemberFiltersStore().$reset();
      return Promise.all([
        context.dispatch('auth/resetState'),
        context.dispatch('clubs/resetState'),
        context.dispatch('labels/resetState'),
        context.dispatch('nav/resetState'),
        context.dispatch('notifications/resetState'),
        context.dispatch('schemes/resetState'),
        context.dispatch('tags/resetState'),
        context.dispatch('users/resetState'),
        context.dispatch('members/resetState'),
      ]);
    },
  },
  modules: {
    auth,
    clubs,
    labels,
    nav,
    notifications,
    schemes,
    tags,
    users,
    members,
  },
  getters,
});
