import { StoreDb } from '@/services/db/index';

class VideosDb extends StoreDb {
  async saveVideo(id: string, buffer: ArrayBuffer): Promise<void> {
    return this.transaction('rw', this.videos, () => {
      this.videos.put({ id, buffer }, id);
    });
  }

  async getVideo(id: string): Promise<ArrayBuffer | null> {
    return this.transaction('r', this.videos, async () => {
      const res = await this.videos
        .where('id')
        .equals(id.toString())
        .first();
      return res ? res.buffer : null;
    });
  }

  async getVideoIds() {
    return this.transaction('r', this.videos, async () => {
      return this.videos.toCollection().primaryKeys();
    });
  }

  async clearVideos(videoIds: Array<string>) {
    return this.transaction('rw', this.videos, async () => {
      return this.videos
        .where('id')
        .anyOf(videoIds)
        .delete();
    });
  }
}

export const videosDb = new VideosDb();
