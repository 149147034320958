
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Online } from '@/components/mixins/online';
import { DataTableOptions } from '@/models/dataTableOptions';
import { Group } from '@/models/group';
import { EventBus } from '@/services/event-bus';
import SearchField from '@/components/search/SearchField.vue';
import SessionDatePicker from './SessionDatePicker.vue';
import moment from 'moment';

@Component({
  name: 'Groups',
  components: {
    SearchField,
    SessionDatePicker,
  },
})
export default class Groups extends mixins(Online) {
  private downloadingClasses = false;
  private page = 1;
  private pageCount = 0;
  private itemsPerPage = 10;
  private itemsPerPageOptions = [10, 50, 100, 250];
  private downloadProgress = [0, 0];

  created() {
    if (this.$router.currentRoute.query.switch) {
      this.$router.replace({ name: 'member-groups' });
    }
    EventBus.$on('user-initialized', () => {
      this.fetchItems();
    });
  }

  getTime(dateTime: string) {
    return dateTime ? moment(dateTime).format('HH:mm') : '';
  }

  fetchItems() {
    if (this.$store.getters['auth/isLoggedIn']()) {
      if (this.online) {
        this.$store.dispatch('groups/fetchItems');
      }
    }
  }

  async sync() {
    await this.$store.dispatch('groups/fetchItems', { forceSync: true });
  }

  get syncing() {
    // 'loading' will be false once the groups are loaded, but members will continue being loaded in the background.
    return this.$store.getters['storeDb/isLoadingGroups'] || this.$store.getters['storeDb/isLoadingMembers'];
  }

  get syncButtonText() {
    return this.syncing ? this.$t('groups.syncing') : this.$t('groups.sync');
  }

  get loadingText() {
    return this.$t('global.loading_text');
  }

  get options(): DataTableOptions {
    const sortBy = this.recordsHaveTime ? ['start_at'] : ['name'];
    return {
      sortBy,
      sortDesc: [false],
      mustSort: true,
    };
  }

  set options(value: DataTableOptions) {
    if (this.online) {
      this.fetchItems();
    }
  }

  get columnHeaders() {
    const headers = [
      {
        text: this.$t('groups.group_name'),
        value: 'name',
        sortable: true,
      },
      {
        text: this.$t('groups.total_members'),
        value: 'total_members',
        sortable: true,
      },
    ];

    if (this.recordsHaveTime) {
      headers.unshift({
        text: this.$t('groups.time') as string,
        value: 'start_at',
        sortable: true,
      });
    }
    return headers;
  }

  get records() {
    // Show all remote groups, and local groups that are not empty
    return this.$store.getters['groups/remoteAndNonEmptyLocalGroups'];
  }

  get recordsHaveTime() {
    return this.records.some((group: Group) => !!group.start_at);
  }

  get loading(): boolean {
    return this.$store.getters['groups/isLoading'];
  }

  rowClick(group: Group) {
    if (group.members_loaded) {
      this.$router.push({ name: 'groups-menu', params: { groupId: group.id.toString() } }).catch(() => {
        // ignore routing errors
      });
    }
  }

  get totalItems(): number {
    return this.records ? this.records.length : 0;
  }

  get downloadingText() {
    if (!this.downloadingClasses) {
      return this.$t('groups.import_all_classes');
    }
    if (this.downloadProgress[1] > 0) {
      return this.$t('global.imported_n', { n: this.downloadProgress[0], total: this.downloadProgress[1] });
    }
    return this.$t('global.importing');
  }

  async downloadAllClasses() {
    this.downloadingClasses = true;
    this.downloadProgress = [0, 0];
    const progress = (n: number, total: number) => {
      this.downloadProgress = [n, total];
    };
    const n = await this.$store.dispatch('groups/fetchItems', { forceSync: true, allGroups: true, progress });
    this.$alert('success', this.$t('groups.download_success', { n }));
    this.downloadingClasses = false;
  }

  get canDownloadClasses() {
    return this.$store.getters['groups/canImportAllGroups']();
  }

  get canAssessInPast() {
    return this.$store.getters['auth/isFeatureEnabled']('CAN_ASSESS_IN_THE_PAST');
  }
}
