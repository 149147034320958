
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/services/event-bus';

@Component
export default class NewVersionDialog extends Vue {
  private dialog = false;

  created() {
    EventBus.$on('new-version-available', () => {
      console.log('New version available!');
      this.dialog = true;
    });
  }

  refreshPage() {
    window.location.reload();
  }
}
