import { FileModel } from '@/models/fileModel';
import { makeRequest } from './api-request';

const MAX_FILE_SIZE = 4 * 1024 * 1024;
const ERROR_TOO_BIG = 'too_big';
const ERROR_INVALID_TYPE = 'Invalid file type';
const ALLOWED_FILE_TYPES = 'png, jpg, doc, docx, txt';

const pFileReader = (file: File, asText = false): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = function(e) {
      if (e.target) {
        resolve(e.target.result);
      } else {
        reject(Error('FileReader failed'));
      }
    };
    asText ? fr.readAsText(file) : fr.readAsDataURL(file);
  });
};

const list = async (): Promise<Array<FileModel>> => {
  try {
    const resp = await makeRequest('GET', '/files/file');
    const respBody = resp.body as Array<FileModel>;
    return respBody;
  } catch {
    return [];
  }
};

const read = async (file: File): Promise<string> => {
  const r = await pFileReader(file, true);
  return typeof r === 'string' ? r : '';
};

const upload = async (file: File, description: string): Promise<string> => {
  if (file.size > MAX_FILE_SIZE) {
    throw new Error(ERROR_TOO_BIG);
  }
  const data = await pFileReader(file);
  const body = JSON.stringify({
    title: file.name,
    description,
    folder: 'file',
    filename: file.name.replaceAll(' ', ''),
    file: data,
  });
  const resp = await makeRequest('POST', '/files', { body });
  const respBody = resp.body as { url: string; filename: string };
  return respBody.url;
};

const remove = async (filename: string): Promise<boolean> => {
  try {
    await makeRequest('DELETE', `/files/file/${filename}`);
    return true;
  } catch {
    return false;
  }
};

export { list, read, upload, remove, MAX_FILE_SIZE, ALLOWED_FILE_TYPES, ERROR_TOO_BIG, ERROR_INVALID_TYPE };
