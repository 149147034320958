import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { TokenResponse } from '@/models/tokenResponse';
import { ErrorResponse } from '@/models/errorResponseBody';
import {
  appTypeToAuthType,
  parseTokenSubject,
  getRefreshTokenExpiry,
  retrieveToken,
  retrieveRefreshToken,
  retrieveClubs,
  getBaseUrl,
  saveLanguageToLocalStorage,
  saveJsonToLocalStorage,
  retrieveLanguage,
  getClubUsers,
  clearLocalStorage,
  saveNewTokenResponse,
  getValidRefreshToken,
  sendTokenToServiceWorker,
} from '@/services/auth';
import { AuthResponse } from '@/models/authResponse';
import { AuthAccess } from '@/models/authAccess';
import { AuthUser } from '@/models/authUser';
import { Client } from '@/models/client';
import { ClubMemberTypes } from '@/consts';
import i18n from '@/i18n';
import { ApiLanguage, Language } from '@/models/language';
import { makeRequest } from '@/services/api-request';
import { EventBus } from '@/services/event-bus';
import { ResponseError } from '@/models/responseError';

interface AuthState {
  user: AuthUser | null;
  clubIds: number[];
  token: AuthAccess | null;
  refreshTokenExpiry: number;
  client: Client | null;
  language: ApiLanguage | null;
  languages: Array<ApiLanguage>;
  initialized: boolean;
}

const getDefaultState = (): AuthState => ({
  user: null,
  clubIds: [],
  token: null,
  refreshTokenExpiry: 0,
  client: null,
  language: null,
  languages: [],
  initialized: false,
});

let versionInterval: NodeJS.Timer | null = null;

const state = getDefaultState();

const mutations = {
  resetState: (moduleState: AuthState) => {
    // Don't reset client, languages and language
    const saved = { client: moduleState.client, languages: moduleState.languages, language: moduleState.language };
    Object.assign(moduleState, getDefaultState(), saved);
  },
  updateToken: (moduleState: AuthState, token: AuthAccess) => {
    moduleState.token = token;
  },
  updateRefreshTokenExpiry: (moduleState: AuthState, refreshTokenExpiry: number) => {
    moduleState.refreshTokenExpiry = refreshTokenExpiry;
  },
  retreiveAndUpdateToken: (moduleState: AuthState) => {
    const refreshToken = retrieveRefreshToken();
    const refreshTokenExpiry = getRefreshTokenExpiry(refreshToken);
    moduleState.refreshTokenExpiry = refreshTokenExpiry;

    const token = retrieveToken();
    if (!token) {
      return false;
    }
    const authAccess = parseTokenSubject(token);
    moduleState.token = authAccess;
  },
  updateClubIds: (moduleState: AuthState, clubIds: string[]) => {
    moduleState.clubIds = clubIds.map(id => parseInt(id, 10));
  },
  updateUser: (moduleState: AuthState, user: AuthUser) => {
    moduleState.user = user;
  },
  updateClient: (moduleState: AuthState, client: Client) => {
    moduleState.client = client;
  },
  updateLanugage: (moduleState: AuthState, language: ApiLanguage) => {
    moduleState.language = language;
  },
  updateLanguages: (moduleState: AuthState, data: Array<ApiLanguage>) => {
    moduleState.languages = data || [];
  },
  updateInitialized: (moduleState: AuthState, value: boolean) => {
    moduleState.initialized = value;
  },
};

const actions = {
  resetState: async (context: ActionContext<AuthState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  logout: async (context: ActionContext<AuthState, RootState>): Promise<boolean> => {
    if (context.state.token) {
      clearLocalStorage();
      await context.dispatch('resetState', null, { root: true });
      return true;
    }
    return false;
  },
  login: async (
    context: ActionContext<AuthState, RootState>,
    {
      clientId,
      authType,
      provider,
      extra = {},
    }: { clientId: string; authType: string; provider: string; extra: Record<string, any> }
  ): Promise<true | ResponseError | false> => {
    try {
      const language = retrieveLanguage();
      const languageId = language ? language.id : null;
      const params = {
        client_id: clientId,
        type: authType,
        provider,
        language_id: languageId,
      };
      const response: AuthResponse = await makeRequest(
        'POST',
        '/auth/login',
        {
          body: JSON.stringify(Object.assign(params, extra)),
        },
        false
      );

      if (!response.response.ok) {
        throw new Error(`An error has occured: ${response.response.statusText}`);
      }

      const tokenResponse = response.body as TokenResponse;

      if (tokenResponse.reset === true) {
        // Force user to reset password in Coursepro before logging in
        const clientId = context.getters.clientId;
        const url = `/auth/reset-url?client_id=${clientId}`;
        const resp: AuthResponse = await makeRequest('GET', url, {}, false);
        const resetUrl = resp.body.url as string | undefined;

        // Give user time to read error message
        setTimeout(() => {
          window.open(resetUrl, '_blank');
        }, 1500);

        return false;
      }

      const [authAccess, refreshTokenExpiry] = saveNewTokenResponse(response.body as TokenResponse);
      await context.dispatch('resetState', null, { root: true });
      context.commit('updateToken', authAccess);
      context.commit('updateRefreshTokenExpiry', refreshTokenExpiry);
      EventBus.$emit('user-login');
      return true;
    } catch (e) {
      console.error('Error in login: ', e);
      return e as ResponseError;
    }
  },
  remoteLogin: async (
    context: ActionContext<AuthState, RootState>,
    { code, clientId, authType }: { code: string; clientId: string; authType: string }
  ): Promise<boolean | ResponseError> => {
    try {
      const url = `/auth/remote-login?code=${code}&redirect=${clientId}:${authType}`;
      const resp: AuthResponse = await makeRequest('GET', url, {}, false);
      const [authAccess, refreshTokenExpiry] = saveNewTokenResponse(resp.body as TokenResponse);
      await context.dispatch('resetState', null, { root: true });
      context.commit('updateToken', authAccess);
      context.commit('updateRefreshTokenExpiry', refreshTokenExpiry);
      EventBus.$emit('user-login');
      return true;
    } catch (e) {
      console.error('Error in remote login: ', e);
      return e as ResponseError;
    }
  },
  setPassword: async (context: ActionContext<AuthState, RootState>, user: object): Promise<[boolean, string]> => {
    try {
      const resp: AuthResponse = await makeRequest('POST', '/auth/set-password', { body: JSON.stringify(user) }, false);
      const [authAccess, refreshTokenExpiry] = saveNewTokenResponse(resp.body as TokenResponse);
      await context.dispatch('resetState', null, { root: true });

      context.commit('updateToken', authAccess);
      context.commit('updateRefreshTokenExpiry', refreshTokenExpiry);
      EventBus.$emit('user-login');
      return [true, ''];
    } catch (e) {
      const error = e as ErrorResponse;
      return [false, error.body.detail];
    }
  },
  changeToken: async (
    context: ActionContext<AuthState, RootState>,
    { path, data }: { path: string; data: object }
  ): Promise<boolean> => {
    try {
      const refreshToken = getValidRefreshToken();
      const url = `${getBaseUrl()}${path}`;
      const body = JSON.stringify(Object.assign({ refresh_token: refreshToken }, data));
      const resp: AuthResponse = await makeRequest('POST', url, { body });
      const [authAccess, refreshTokenExpiry] = saveNewTokenResponse(resp.body as TokenResponse);
      await context.dispatch('resetState', null, { root: true });
      context.commit('updateToken', authAccess);
      context.commit('updateRefreshTokenExpiry', refreshTokenExpiry);
      EventBus.$emit('user-login');
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  checkToken: async (
    context: ActionContext<AuthState, RootState>,
    { token, tokenType }: { token: string; tokenType: string }
  ): Promise<boolean> => {
    try {
      const url = getBaseUrl() + '/auth/check-token';
      const body = JSON.stringify({ token: token, typ: tokenType });
      await makeRequest('POST', url, { body }, false);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  changeClub: async (context: ActionContext<AuthState, RootState>, clubId: number): Promise<boolean> => {
    return await context.dispatch('changeToken', { path: '/auth/change-club', data: { club_id: clubId } });
  },
  changeLanguage: async (context: ActionContext<AuthState, RootState>, languageId: number): Promise<boolean> => {
    const success = await context.dispatch('changeToken', {
      path: '/auth/change-language',
      data: { language_id: languageId },
    });
    if (success) {
      window.location.reload();
    }
    return success;
  },
  initialize: async (context: ActionContext<AuthState, RootState>): Promise<boolean> => {
    // Get client translations and stylesheet
    if (context.getters.clientId === null) {
      await context.dispatch('initializeClient');
    }

    // Check for new release and if online every minute
    context.dispatch('checkVersionChanged');
    if (!versionInterval) {
      // only set this up on the first initialization to avoid multiple versions of these running in parallel where a
      // user switches club.
      versionInterval = setInterval(() => context.dispatch('checkVersionChanged'), 60000);
    }

    context.dispatch('nav/calculateStyle', { schemeSlug: null, schemeNodeId: null, updateTheme: true }, { root: true });

    await context.dispatch('initializeLanguage');
    context.commit('retreiveAndUpdateToken');

    const clubIds = retrieveClubs();
    if (clubIds) {
      context.commit('updateClubIds', clubIds);
    }

    if (context.getters.isLoggedIn()) {
      try {
        let url = `${getBaseUrl()}/me`;
        if (context.rootGetters.appId === 'parent') {
          url += '?with_last_login=1&with_unsubscribes=1';
        }
        const authResponse: AuthResponse = await makeRequest('GET', url);
        context.commit('updateUser', authResponse.body as AuthUser);
        const refreshToken = getValidRefreshToken();
        if (refreshToken) {
          sendTokenToServiceWorker(refreshToken);
        }

        context.commit('updateInitialized', true);
        return true;
      } catch {
        context.commit('updateInitialized', true);
        return false;
      }
    }
    context.commit('updateInitialized', true);
    return false;
  },

  initializeClient: async (context: ActionContext<AuthState, RootState>) => {
    // Get client translations and stylesheet
    try {
      const hostname = window.location.hostname;
      const url = `/client?hostname=${hostname}`;
      const resp: AuthResponse = await makeRequest('GET', url, {}, false);

      context.commit('updateClient', resp.body.client as Client);
      context.commit('updateApiVersion', resp.response.headers.get('X-API-Version'), { root: true });
    } catch (e) {
      console.error('Error fetching client details', e);
      return false;
    }
  },

  checkVersionChanged: async (context: ActionContext<AuthState, RootState>) => {
    // Only check version in production mode
    if (process.env.NODE_ENV === 'production') {
      const url = `${window.location.origin}/version`;
      const resp = await fetch(url, { cache: 'no-store' });
      const newVersion = await resp.text();

      if (newVersion.trim() !== context.rootState.version) {
        EventBus.$emit('new-version-available');
      }
    }

    // local version check made every 60 seconds so check if online then
    EventBus.$emit('check-online-status');
  },

  initializeLanguage: (context: ActionContext<AuthState, RootState>) => {
    const lanuage = retrieveLanguage();
    if (lanuage) {
      context.commit('updateLanugage', lanuage);
      // Set the correct locale
      i18n.locale = context.getters.locale;
    }
  },

  refreshUser: async (context: ActionContext<AuthState, RootState>): Promise<void> => {
    let url = `${getBaseUrl()}/me`;
    if (context.rootGetters.appId === 'parent') {
      url += '?with_last_login=1&with_unsubscribes=1';
    }
    try {
      const authResponse: AuthResponse = await makeRequest('GET', url);
      context.commit('updateUser', authResponse.body as AuthUser);
    } catch {
      console.error('Error refreshing user');
    }
  },

  getNgoAuthUrl: async (context: ActionContext<AuthState, RootState>): Promise<string> => {
    const clientId = context.getters.clientId;
    const authType = appTypeToAuthType(context.rootGetters.appId);
    const url = `${getBaseUrl()}/auth/auth-url?client_id=${clientId}&type=${authType}`;

    const headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', window.location.hostname);

    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.statusText}`;
      throw new Error(message);
    }

    const responseData = await response.json();
    return responseData.url;
  },

  addClubId: async (context: ActionContext<AuthState, RootState>, clubId: number): Promise<void> => {
    const clubIds = context.getters.clubIds;
    clubIds.push(clubId);
    context.commit('updateClubIds', clubIds);
    saveJsonToLocalStorage('clubs', clubIds);
  },

  fetchAllLanguages: async (context: ActionContext<AuthState, RootState>) => {
    try {
      const clientId: number = context.getters.clientId;
      const authResponse: AuthResponse = await makeRequest('GET', `/auth/languages?client_id=${clientId}`, {}, false);
      context.commit('updateLanguages', authResponse.body as Array<ApiLanguage>);
    } catch (error) {
      console.error(error);
    }
  },

  saveLanguage: async (context: ActionContext<AuthState, RootState>, language: Language) => {
    saveLanguageToLocalStorage({ language_id: language.id, language: language.language, active: language.active });
    await context.dispatch('initializeLanguage');
  },
  updateClientLanguage: async (
    context: ActionContext<AuthState, RootState>,
    data: { languageId: number; active: boolean }
  ) => {
    const url = `/client-languages/${data.languageId}`;
    await makeRequest('PATCH', url, {
      body: JSON.stringify({ active: data.active ? 1 : 0 }),
    });
  },
  switchUser: (context: ActionContext<AuthState, RootState>, userUuid: string) => {
    const clubUsers = getClubUsers();
    const user = clubUsers.find((u: AuthUser) => u.uuid === userUuid);
    if (user) {
      context.commit('updateUser', user);
      context.commit('updateClubIds', user.club_ids);
      localStorage.setItem('token', user.token);
      localStorage.setItem('refresh_token', user.refresh_token);
      const authAccess = parseTokenSubject(user.token);
      context.commit('updateToken', authAccess);
      const refreshTokenExpiry = getRefreshTokenExpiry(user.refresh_token);
      context.commit('updateRefreshTokenExpiry', refreshTokenExpiry);
    } else {
      console.error('Failed to switch user');
    }
  },
};

const getters = {
  isLoggedIn: (moduleState: AuthState) => () => moduleState.refreshTokenExpiry >= Date.now(),
  loggedInClubId: (moduleState: AuthState) => (moduleState.token !== null ? moduleState.token.club_id : null),
  loggedInUser: (moduleState: AuthState) => moduleState.user,
  isOrigUser: (moduleState: AuthState) => () =>
    moduleState.user && moduleState.user.uuid === localStorage.getItem('origUserUuid'),
  loggedInClubHasParentPortal: (moduleState: AuthState) => moduleState.user?.club?.parent_portal === 1,
  loggedInClubHasLocalMembers: (moduleState: AuthState) =>
    moduleState.user?.club?.member_types !== ClubMemberTypes.REMOTE_ONLY,
  loggedInClubHasRemoteMembers: (moduleState: AuthState) =>
    moduleState.user?.club?.member_types !== ClubMemberTypes.LOCAL_ONLY,
  loggedInClubIsOrganisation: (moduleState: AuthState) =>
    moduleState.user?.club?.ngo_id?.includes('ORG') && moduleState.user?.club?.name.includes('@'),
  loggedInUserId: (moduleState: AuthState) => (moduleState.user ? moduleState.user.uuid : ''),
  loggedInUserNgoId: (moduleState: AuthState) => (moduleState.user ? moduleState.user.ngo_id : ''),
  loggedInUserName: (moduleState: AuthState) =>
    moduleState.user ? `${moduleState.user.firstname} ${moduleState.user.lastname}` : '',
  clubIds: (moduleState: AuthState) => moduleState.clubIds,
  clientDetails: (moduleState: AuthState) => (moduleState.client ? moduleState.client : null),
  clientId: (moduleState: AuthState) => (moduleState.client ? moduleState.client.id : null),
  authProvider: (moduleState: AuthState) => (moduleState.client ? moduleState.client.auth_provider : null),
  languageId: (moduleState: AuthState) => (moduleState.token !== null ? moduleState.token.language.language_id : null),
  locale: (moduleState: AuthState) => (moduleState.language ? moduleState.language.language.split('-')[0] : null),
  currentLocale: () => i18n.locale,
  languages: (moduleState: AuthState) => moduleState.languages,
  getFeatureValue: (moduleState: AuthState) => (feature: string) => {
    if (!moduleState.token || !moduleState.token.features) {
      return null;
    }
    const name = feature.toUpperCase();
    return moduleState.token.features[name];
  },
  isFeatureEnabled: (moduleState: AuthState, getters: any) => (feature: string) => {
    // this is just always false for BG, always true for CP
    const courseproOnlyFeatures = ['ADD_SCHEME', 'SHOW_CURRENT_LEVEL'];

    // this is always false for BG, sometimes true for CP
    // note: ENABLE_SESSION_PLANNING turns on session plan groups
    const bgDisabledFeatures = ['ENABLE_SESSION_PLANNING', 'SWITCH_USER', 'CAN_EDIT_SELF', 'SHOW_PERCENTAGE_COMPLETE'];

    const client = moduleState.client ? moduleState.client : null;
    if (!client) {
      // Shouldn't happen
      return false;
    }

    // Some features are never given to BG
    if (courseproOnlyFeatures.includes(feature)) {
      return client.auth_provider !== 'bg';
    }

    // for everything else, we give it to all BG users, unless it is in the disabled list.
    if (client.auth_provider !== 'coursepro') {
      return !bgDisabledFeatures.includes(feature);
    }
    return getters.getFeatureValue(feature) === true;
  },
  isOrganisationPortal: (moduleState: AuthState, getters: any, rootState: any, rootGetters: any) =>
    rootGetters.appId === 'club' && (getters.getFeatureValue('ENABLE_ORGANISATIONS') || false),
  initialized: (moduleState: AuthState) => moduleState.initialized,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
