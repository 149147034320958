
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Online } from '@/components/mixins/online';
import { getReadableDate } from '@/services/utils';
import moment from 'moment';

@Component
export default class SessionDatePicker extends mixins(Online) {
  get sessionDate() {
    return this.$store.getters['groups/sessionDate'];
  }

  set sessionDate(date) {
    this.$store.dispatch('groups/changeDate', { date });
  }

  get currentDate() {
    return getReadableDate(this.sessionDate);
  }

  get maxDays(): number {
    const maxDays = parseInt(this.$store.getters['auth/getFeatureValue']('MAX_PAST_DAYS_ATTENDANCE'), 10);
    return maxDays == null ? 0 : maxDays;
  }

  get canChangeDate() {
    return this.maxDays > 0;
  }

  allowedDates(date: string) {
    const daysAgo = moment().diff(moment(date), 'days', true);
    return daysAgo >= 0 && daysAgo < this.maxDays + 1; // we do not count today
  }

  get today() {
    return moment().format('YYYY-MM-DD');
  }

  showToday() {
    this.sessionDate = this.today;
  }

  get isToday() {
    return this.sessionDate === this.today;
  }
}
