
import { Component, Prop } from 'vue-property-decorator';
import { ContentType, SchemeNodeContent } from '@/models/schemeNodeModel';
import RenderSkillContent from '@/components/common/RenderSkillContent.vue';
import { SchemeScope } from '@/components/mixins/schemescope';
import { mixins } from 'vue-class-component';

@Component({
  name: 'NodeBody',
  components: {
    RenderSkillContent,
  },
})
export default class NodeBody extends mixins(SchemeScope) {
  @Prop() private content!: Array<SchemeNodeContent>;
  @Prop() private modal!: boolean;

  handleLink(schemeNodeId: string) {
    if (this.modal) {
      this.$emit('skill-clicked', schemeNodeId);
    } else {
      this.$emit('skill-opened', schemeNodeId);
    }
  }

  get filteredContent() {
    if (!this.content) {
      return this.content;
    }
    return this.content.filter(c => c.value && c.type !== ContentType.Video);
  }
}
