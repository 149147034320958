
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/services/event-bus';
import { Alert } from '@/models/alert';

@Component
export default class GlobalAlert extends Vue {
  private alerts = [] as Alert[];

  created() {
    EventBus.$on('global-alert', (alert: Alert) => {
      this.alerts.push(alert);
      setTimeout(() => {
        this.dismiss(alert.id);
      }, alert.duration);
    });
  }

  dismiss(id: string) {
    this.alerts = this.alerts.filter(alert => alert.id !== id);
  }
}
