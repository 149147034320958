import { AppIds } from '@/consts';

const state = {
  appId: process.env.VUE_APP_BUILD_TARGET as AppIds,
  version: process.env.APP_VERSION as string,
  buildDate: process.env.APP_BUILT as string,
  indexHtmlModifiedAt: '',
  apiVersion: '?',
};

type IState = typeof state;

const mutations = {
  updateIndexHtmlModifiedAt: (moduleState: IState, indexHtmlModifiedAt: string) => {
    moduleState.indexHtmlModifiedAt = indexHtmlModifiedAt;
  },
  updateApiVersion: (moduleState: IState, apiVersion: string) => {
    moduleState.apiVersion = apiVersion;
  },
};

const getters = {
  appId: (state: IState) => state.appId,
};

export { state, mutations, getters, IState };
