
import { Component, Prop } from 'vue-property-decorator';
import { SchemeNodeModel } from '@/models/schemeNodeModel';
import NodeBody from '@/components/common/NodeBody.vue';
import SchemeList from './SchemeList.vue';
import RenderSkillVideo from '@/components/common/RenderSkillVideo.vue';
import { allNodes } from '@/services/scheme';
import _groupBy from 'lodash/groupBy';
import { SchemeScope } from '@/components/mixins/schemescope';
import { mixins } from 'vue-class-component';
import { EventBus } from '@/services/event-bus';

@Component({
  name: 'SchemeNodeComponent',
  components: {
    NodeBody,
    RenderSkillVideo,
    SchemeList,
    ViewSkillModal: () => import(/* webpackChunkName: "coach-router-bundle" */ './ViewSkillModal.vue'),
  },
})
export default class SchemeNodeComponent extends mixins(SchemeScope) {
  // Passed in via the router.
  @Prop() schemeNodeId!: string;
  @Prop({ default: false }) modal!: boolean;

  private search = '';
  private dialogOpen = false;
  private dialogNodeId = '';

  async created() {
    // Load scheme if not already loaded
    if (this.schemeSlug && !this.$store.getters['schemes/getScheme'](this.schemeSlug)) {
      await this.$store.dispatch('schemes/loadScheme', { raw: false, slug: this.schemeSlug });
      await this.$store.dispatch('schemes/getGrades', this.schemeSlug);
      await this.$store.dispatch('nav/updateSchemeBreadcrumbs', { schemeSlug: this.schemeSlug });
    }
    if (!this.modal) {
      await this.$store.dispatch('nav/calculateStyle', { schemeNodeId: '', schemeSlug: this.slug, updateTheme: true });
    }
    // Ensure theme is correct if this is the first page to load
    EventBus.$on('user-initialized', () => {
      this.$store.dispatch('nav/calculateStyle', { schemeNodeId: '', schemeSlug: this.slug, updateTheme: true });
    });
  }

  /**
   * Computed getter function for returning a scheme node by ID.
   * The change of scheme node ID updated on navigation by injected router props will
   * trigger the update.
   * There is no content populated in the returned node.
   *
   * @return {SchemeNodeModel}
   */
  get schemeNode(): SchemeNodeModel {
    return this.getNode(this.schemeNodeId || '/');
  }

  get isLeaf(): boolean {
    return this.schemeNode.children && this.schemeNode.children.length === 0;
  }

  /**
   * builds link to parent node or home if not applicable
   */
  get backLink(): string | boolean {
    return this.schemeNode.parentId ? this.getNodePath(this.schemeNode.parentId) : false;
  }

  /**
   * Returns how many levels below the root we are at
   * @return {number}
   */
  get depthLevel(): number {
    return this.getDepth(this.schemeNode.id);
  }

  get cssClass() {
    switch (this.depthLevel) {
      case 0:
        return 'top-box'; // root
      case 1:
        return 'box';
      default:
        return 'list';
    }
  }

  get allNodes() {
    const tree = this.scheme;
    return allNodes(tree);
  }

  handleSearch(item: any) {
    if (!item) {
      return;
    }
    this.search = '';
    this.$router.push({ path: this.getNodePath(item.id) }).catch(() => {
      // Ignore routing errors
    });
  }

  // A list of child nodes grouped by group
  get nodeGroups() {
    return _groupBy(this.schemeNode.children, node => node.group || 'none');
  }

  openSkillDialog(nodeId: string) {
    this.dialogNodeId = nodeId;
    this.dialogOpen = true;
  }

  changeSkill(nodeId: string) {
    this.$emit('skill-clicked', nodeId);
  }
}
